<template>
  <div class="testimoni-container">
    <div class="section-title">
      <h1>Testimoni</h1>
    </div>
    <div style="margin-top: 20px;">
      <!-- Swiper Container -->
      <swiper
        :slides-per-view="1"
        :space-between="30"
        :pagination="{ clickable: true }"
        navigation
        autoplay="{ delay: 3000, disableOnInteraction: false }"
      >
        <!-- Slides -->
        <swiper-slide>
          <img class="testimonial-image" src="../assets/Testi.png" alt="Test Image" />
        </swiper-slide>
        <swiper-slide>
          <img class="testimonial-image" src="../assets/Testi.png" alt="Test Image" />
        </swiper-slide>
        <swiper-slide>
          <img class="testimonial-image" src="../assets/Testi.png" alt="Test Image" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

export default {
  name: 'TestimoniPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    // Pastikan Swiper hanya diinisialisasi setelah DOM sepenuhnya tersedia
    this.$nextTick(() => {
      document.title = 'Testimoni di Jasa Tugas dan Skripsi';
    });
  },
};

</script>

<style scoped>
.section-title {
  text-align: center;
  padding: 20px;
  background-color: #4CAF50;
  color: white;
  border-radius: 8px;
}

.section-title h1 {
  margin: 0;
}

.testimoni-container {
  padding: 20px;
}

.testimonial-image {
  width: 50%;
  display: block;
  margin: 0 auto;
}

/* Custom Style untuk Tombol Navigasi */
.swiper-button-next,
.swiper-button-prev {
  color: #4CAF50;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

/* Jika menggunakan posisi absolut */
.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

/* Mengatur tampilan pagination */
.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.swiper-pagination-bullet {
  background-color: #4CAF50;  /* Mengubah warna titik */
  width: 12px;  /* Ukuran titik */
  height: 12px;  /* Ukuran titik */
  margin: 0 5px;  /* Jarak antar titik */
  border-radius: 50%;  /* Membuat titik bulat */
}

.swiper-pagination-bullet-active {
  background-color: #FF6347;  /* Mengubah warna titik aktif */
}
</style>
